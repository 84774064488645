import {NgModule} from '@angular/core';
import {
  RouterModule,
  Routes
} from '@angular/router';
import {PageArtasComponent} from "./pages/pages-jome/page-artas/page-artas.component";
import {PageArtasProductsComponent} from "./pages/pages-jome/page-artas/page-artas-products/page-artas-products.component";
import {PageArtasServices} from "./services/pages/page-artas.services";
import {PageHomeComponent} from "./pages/pages-jome/page-art-party/page-home/page-home.component";
import {PageControllerSiteComponent} from "./pages/pages-jome/page-controller-site/page-controller-site.component";




const routes_ART_PARTY: Routes = [
  {
    path: "",
    component: PageHomeComponent,
    pathMatch: 'full',
  },
  {
    path: "test",
    component: PageArtasProductsComponent,
    pathMatch: 'full',
  },
  {
    path: ":slug",
    component: PageArtasProductsComponent,
    pathMatch: 'full',
  },
   {
    path: "**",
    component: PageHomeComponent,
  } ,

];
const routes_ARTAS: Routes = [

  {
    path: "",
    component: PageArtasComponent,
    pathMatch: 'full',

  },
  // {
  //   path: "controllers-site",
  //   component: PageControllerSiteComponent,
  //   pathMatch: 'full',
  // },
  {
    path: ":slug",
    component: PageArtasProductsComponent,
    pathMatch: 'full',
  },

  {
    path: "**",
    component: PageArtasComponent,
  },


];

@NgModule({
  imports: [RouterModule.forRoot(routes_ART_PARTY, {})],
  // imports: [RouterModule.forRoot(routes_ARTAS, {})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
