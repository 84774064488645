import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import {ScrollPanelModule} from "primeng/scrollpanel";
import {
  NgClass,
  NgForOf,
  NgIf
} from "@angular/common";
import {MyDataServices} from "../../../../services/my-data.services";
import {OrderFormComponent} from "../order-form/order-form.component";
@Component({
  selector: 'app-contacts-messengers',
  standalone: true,
  imports: [
    NgForOf,
    NgIf,
    ScrollPanelModule,
    OrderFormComponent,
    NgClass,
  ],
  templateUrl: './contacts-messengers.component.html',
})
export class ContactsMessengersComponent {
  @Input() public  classBg:any ='bg-color-custom';

  public showContacts: boolean =true;
  public showOrderForm: boolean =false;
  @Input() public  left:any = 80;
  @Input() public  bottom:any =80;
  @Input() public  selectCard:any;
  @Input() showForm: any;

  constructor(public myData:MyDataServices,) {
  }
  ngOnInit() {
  }
  ngAfterViewInit(){
  }





}
