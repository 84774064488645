<app-art-party-product *ngIf="selectedProduct && showProduct"
                       [card]="selectedProduct"
/>
<app-wrapper-card>

  <section class="bg-gray-800 bg-opacity-50 p-6 rounded-lg relative">
    <app-text-block
      [name]="selectedProduct.name"
      [text]="selectedProduct.desc"
    />

    <!-- Image Display Section -->
    <figure class="relative flex justify-center items-center my-5 transition-opacity duration-300 ease-in-out"
            [ngClass]="{'opacity-0': isFadingOut, 'opacity-100': !isFadingOut}">
      <div
        class=" w-96 h-96 rounded-lg shadow-lg cursor-pointer rounded-lg shadow-lg  border border-zinc-700 text-center aspect-square relative  inline-block transition-transform transform hover:scale-105">
        <app-wrapper-swiper
          (swipeLeft)="nextProduct()"
          (swipeRight)="prevProduct()">
          <img *ngIf="!selectedProduct.showZoom"
               [src]="selectedProduct.srcPreview" alt="{{ selectedProduct.name }}"
               class="w-96 h-96  rounded-lg shadow-lg cursor-pointer"
          >
          <app-zoom-image *ngIf="selectedProduct.showZoom"
                          [srcImage]="selectedProduct.srcPreview">
          </app-zoom-image>
        </app-wrapper-swiper>


        <button (click)="selectedProduct.showZoom = !selectedProduct.showZoom;"
                class=" z-20 text-sm absolute w-[50px] h-[50px] top-0 left-2 bg-zinc-900 text-white p-1 rounded-full transition-transform transform hover:scale-125"
        >
          <i *ngIf="!selectedProduct.showZoom" [class]="zoomPlusIcon"></i>
          <i *ngIf="selectedProduct.showZoom" [class]="zoomMinusIcon"></i>
        </button>
        <div class="flex flex-row gap-1 w-[150px] ">

          <button
            class=" absolute bottom-3 right-0 w-full bg-gradient-to-r from-purple-500/65 to-indigo-500/65 hover:from-purple-600 hover:to-indigo-600 text-white font-bold py-1 px-1 rounded-md shadow-lg transition duration-300 ease-in-out transform hover:scale-105  fade-out"
            (click)="showProduct =!showProduct"
          >
            Галерея:
            <!--            <span *ngIf="selectedProduct.countBox">{{selectedProduct.countBox}}</span>-->
            <i class="fa-solid fa-circle-chevron-right"></i>
          </button>

        </div>
      </div>
      <div class="absolute top-2 -right-[40px] flex flex-col gap-1">
        <ng-container *ngFor="let material of materials">
          <div class="flex items-center bg-black bg-opacity-50 text-yellow-400 px-2 py-1 rounded text-xs">
            <i class="fas fa-check mr-1"></i> <!-- Checkmark icon -->
            {{ material }}
          </div>
        </ng-container>
      </div>
    </figure>

    <figcaption class="bg-black bg-opacity-70 text-yellow-400 font-bold text-lg px-3 py-1 rounded-lg"
                style="text-align: justify;">
      <s class="text-gray-400 text-sm">{{ selectedProduct.status }}</s>
      {{ selectedProduct.action }}
    </figcaption>

    <!-- Navigation Arrows -->
    <button (click)="prevProduct()"
            class="absolute left-0 top-1/2 transform -translate-y-1/2 bg-gray-700 text-white rounded-full p-2 z-10">
      <i class="fas fa-chevron-left"></i> <!-- Left arrow icon -->
    </button>

    <button (click)="nextProduct()"
            class="absolute right-0 top-1/2 transform -translate-y-1/2 bg-gray-700 text-white rounded-full p-2 z-10">
      <i class="fas fa-chevron-right"></i> <!-- Right arrow icon -->
    </button>
  </section>

  <div class="relative">
    <button (click)="scrollLeft()"
            class="absolute left-0 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white rounded-full p-2 z-10">
      <i class="fas fa-chevron-left"></i> <!-- Left arrow icon -->
    </button>


    <div id="thumbnails_products" class="flex gap-3 overflow-x-auto p-2">
      <div *ngFor="let product of filteredProducts; let i = index; trackBy: trackByProduct"
           class="relative filter-item flex-shrink-0 w-24 h-24 cursor-pointer group transition-transform duration-300 ease-in-out"
           [class.selected-thumbnail]="i === selectedProductIndex"
           (click)="selectProduct(product,i)">
        <div class="absolute top-2 left-2 bg-black bg-opacity-70 text-yellow-400 px-2 py-1 rounded text-xs">
          {{ product.cost }}
        </div>

        <img [src]="product.srcPreview" alt="{{ product.name }}"
             class="w-full h-full rounded-lg shadow-lg group-hover:scale-110 group-hover:shadow-xl transition-transform duration-300 ease-in-out">
        <div *ngIf="selectedProduct === product"
             class="absolute bottom-1 left-2 bg-black bg-opacity-70 text-yellow-400 text-xs font-bold rounded px-2 py-1">
          Вибрано
        </div>
      </div>
    </div>

    <button (click)="scrollRight()"
            class="absolute right-0 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white rounded-full p-2 z-10">
      <i class="fas fa-chevron-right"></i> <!-- Right arrow icon -->
    </button>


  </div>
  <div *ngIf="showFilters"
       class="bg-gray-800 mt-2 flex flex-col items-center mb-4 transition-all duration-300 ease-in-out transform">
    <div class="grid grid-cols-1 gap-4">
      <div class="flex justify-center">
        <button (click)="filterProductsByCost('Бюджетні')"
                class="bg-gray-800 text-white text-sm px-4 py-2 rounded-md shadow-md transition-transform duration-300 ease-in-out transform hover:scale-105">
          Бюджетні
        </button>
        <button (click)="filterProductsByCost('Середні')"
                class="bg-gray-800 text-white text-sm px-4 py-2 rounded-md shadow-md transition-transform duration-300 ease-in-out transform hover:scale-105">
          Середні
        </button>
        <button (click)="filterProductsByCost('Преміум')"
                class="bg-gray-800 text-white text-sm px-4 py-2 rounded-md shadow-md transition-transform duration-300 ease-in-out transform hover:scale-105">
          Преміум
        </button>
      </div>
      <div class="flex justify-center">
        <button (click)="filterAdditionally('Портретний')"
                class="bg-gray-800 text-white text-sm px-4 py-2 rounded-md shadow-md transition-transform duration-300 ease-in-out transform hover:scale-105">
          Портретний
        </button>
        <button (click)="filterAdditionally('Персонажний')"
                class="bg-gray-800 text-white text-sm px-4 py-2 rounded-md shadow-md transition-transform duration-300 ease-in-out transform hover:scale-105">
          Персонажний
        </button>
        <button (click)="filterAdditionally('Сюжетний')"
                class="bg-gray-800 text-white text-sm px-4 py-2 rounded-md shadow-md transition-transform duration-300 ease-in-out transform hover:scale-105">
          Сюжетний
        </button>
      </div>
    </div>

  </div>

  <div class="flex justify-between mb-4">
    <button
      class="w-full bg-gradient-to-r from-purple-500/65 to-indigo-500/65 hover:from-purple-600 hover:to-indigo-600 text-white font-bold py-1 px-1 rounded-md shadow-lg transition duration-300 ease-in-out transform hover:scale-105 mr-2"
      (click)="showFilters = !showFilters; resetFilters()"
    >
     <span *ngIf="!showFilters">
       Фільтрація
        <i class="fas fa-filter transition-transform duration-300"></i>
     </span>
      <span *ngIf="showFilters">
        Закрити
      <i class="fas fa-times transition-transform duration-300"></i>
      </span>

    </button>

    <button
      class="w-full bg-gradient-to-r from-purple-500/65 to-indigo-500/65 hover:from-purple-600 hover:to-indigo-600 text-white font-bold py-1 px-1 rounded-md shadow-lg transition duration-300 ease-in-out transform hover:scale-105"
      (click)="showForm = !showForm;"
    >
      <span *ngIf="!showForm">
       Форма
              <i class="fas fa-file-alt transition-transform duration-300"></i>

     </span>
      <span *ngIf="showForm">
        Закрити
      <i class="fas fa-times transition-transform duration-300"></i>
      </span>
    </button>
  </div>
  <form *ngIf="showForm" class="mt-5" (ngSubmit)="handleSubmit()">
    <div class="mb-4">

      <label for="phone" class="text-white block text-sm font-bold mb-2">Ваш номер телефону
        <span class="text-green-500 text-lg mt-1">*</span>
      </label>
      <input type="tel" id="phone"
             [(ngModel)]="form.phone"
             placeholder="Введіть номер"
             required
             class="w-full p-3 bg-gray-600 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-400">


    </div>

    <div class="mb-4">
      <label for="comment" class="text-white block text-sm font-bold mb-2">Коментар</label>
      <textarea id="comment" [(ngModel)]="form.comment" rows="3" placeholder="Ваші побажання"
                class="w-full p-3 bg-gray-600 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-400"></textarea>
    </div>

    <div class="text-white block text-[12px] font-bold m-2">
      Для малюнку подріблено фото та опис в строках орієнтуюсь на Вас!
    </div>

    <button type="submit" id="submit-btn"
            class="w-full bg-yellow-400 text-gray-800 font-bold py-3 rounded-lg hover:bg-yellow-300 transition-colors flex items-center justify-center"
            [disabled]="form.phone === ''"> <!-- Disable button if phone is empty -->
      Надіслати:(
      <span class="w-[150px] overflow-hidden whitespace-nowrap text-ellipsis" title="{{ selectedProduct.name }}">
      {{ selectedProduct.name }}
    </span>
      )
    </button>

    <div *ngIf="isFormSubmitted" class="text-center text-yellow-400 font-bold text-lg mt-3">
      Я зв'яжусь з вами найближчим часом...
    </div>

    <div *ngIf="form.phone === ''" class="text-green-500 text-sm mt-2">
      Будь ласка, заповніть поле телефону перед відправкою форми.
    </div>
  </form>
</app-wrapper-card>
