<ng-container *ngIf="card">
<div   style="height:25px; overflow: hidden"
       class="absolute top-0 seo-container text-center z-0">
  <h1>{{dataPage.seoTexts.h1}}</h1>
  <h2>{{dataPage.seoTexts.h2}}</h2>
  <h3>{{dataPage.seoTexts.h3}}</h3>
  <p>{{dataPage.seoTexts.text}}</p>
</div>

  <div class="z-50 -right-[0px] fixed top-[200px] bg-zinc-800 bg-opacity-70 rounded-lg p-1">
    <div class="flex flex-col items-start">
     <!-- <img
        [src]="card.srcIcon"
        [alt]="dataPage.seoTexts.h1"
        class="w-24 h-24 object-cover rounded-md shadow-md opacity-90"
      >-->
      <div class="flex flex-col items-start space-y-2 p-1">
        <div *ngFor="let label of labels; let i = index" class="flex items-center">
          <span class="text-zinc-400 mr-2">&#10003;</span> <!-- Checkmark glyph -->
          <div class="text-[10px] bg-opacity-90 bg-zinc-800 text-zinc-400 shadow-sm rounded-md px-1 py-1 transform transition duration-300 ease-out opacity-90">
            {{ label }}
          </div>
        </div>
      </div>
    </div>
  </div>
<app-contacts-left-artas
  [showForm]="false"
>
</app-contacts-left-artas>
<app-navbar-top-artas
  [routeHome]="'/zakazat-sharj-po-foto'"
></app-navbar-top-artas>


<app-navbar-top-artas
  [routeHome]="this.subRoute"
  [logoText]="this.logoText"
></app-navbar-top-artas>
<app-product-list-right-artas
  [cards]="cards"
  [selectCard]="card"
  (onSelectCard)="setSelect($event)"
></app-product-list-right-artas>
  <app-contacts-messengers
    [showForm]="true"
    [selectCard]="card"
    [bottom]="75"
    [left]="95"
  ></app-contacts-messengers>
<!--<app-contacts-left-artas
  [showForm]="true"
  [selectCard]="card">
</app-contacts-left-artas>-->
<app-product-more-artas
  [selectCard]="card"
></app-product-more-artas>
  <app-view-grid-artas *ngIf="photos"
                       [cards]="photos"
                       [selectCard]="card"
  ></app-view-grid-artas>

</ng-container>
