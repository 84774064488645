import {Component, EventEmitter, inject, Input, OnInit, Output} from '@angular/core';
import {NgFor, NgIf} from '@angular/common';
import {OverlayPanelModule} from "primeng/overlaypanel";
import {CdServices} from "../../../../services/common/cd.services";
import {GifGeneratorComponent} from "../../../../components/image/gif-generator/gif-generator.component";
import {TextSliceComponent} from "../../../../components/text-slice/text-slice.component";
import {
  basketIcon,
  clockIcon, envelopeIcon,
  favoriteOpenIcon,
  folderIcon,
  levelIcon,
  listIcon,
  tagIcon
} from "../../../../traits/icons-tools";
import {RouterLink} from "@angular/router";
import {OrderByImageFormComponent} from "../order-by-image-form/order-by-image-form.component";
import {ActionComponent} from "../action/action.component";
import {SvgIconComponent} from "../../../../components/image/svg-icon/svg-icon.component";
import {ContactsMessengersComponent} from "../contacts-messengers/contacts-messengers.component";

@Component({
  selector: 'app-card-artas',
  templateUrl: './card-artas.component.html',
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    OverlayPanelModule,
    GifGeneratorComponent,
    TextSliceComponent,
    OrderByImageFormComponent,
    ActionComponent,
    SvgIconComponent,
    RouterLink,
    ContactsMessengersComponent,
  ]
})
export class CardArtasComponent implements OnInit {
  @Input() showImage: boolean = true;
  @Input() card: any;
  @Input() bgGlobalClass: string = 'bg-[#4a0620]';
  @Input() bgClass: string = 'bg-[#4a0620]';
  @Input() borderClass: string = 'border-[#4a0620]';
  @Output() onDeleteFromFavorite: EventEmitter<any> = new EventEmitter();

  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  @Output() onSelectCourse: EventEmitter<any> = new EventEmitter();
  @Output() onAddFavorite: EventEmitter<any> = new EventEmitter();
  @Output() onFrameShow: EventEmitter<any> = new EventEmitter();
  @Output() onShowPhotos: EventEmitter<any> = new EventEmitter();
  @Output() onClickRoute: EventEmitter<any> = new EventEmitter();
 public showOrderByCard: boolean =false;

  ngOnInit():void {
  }
  constructor(public cd: CdServices,) {
  }

  showPhotosBoxBtn(item: any, uploaded: string): void {
    this.onShowPhotos.emit(item)
  }

  showVideos(item: any, uploaded: string): void {
    this.onSelect.emit(item)

  }

  showPalletesBlock(item: any, uploaded: string): void {
    this.onSelect.emit(item)

  }
  get labels() {
    return  this.card.type.split('_').filter((word) => word.trim());
  }
  showBrushesPhotoshopBlock(item: any, uploaded: string): void {
    this.onSelect.emit(item)


  }

  protected readonly clockIcon = clockIcon;
  protected readonly listIcon = listIcon;
  protected readonly levelIcon = levelIcon;
  protected readonly folderIcon = folderIcon;
  protected readonly tagIcon = tagIcon;
  protected readonly envelopeIcon = envelopeIcon;
}
