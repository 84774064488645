<div   style="height:25px; overflow: hidden"
       class="absolute top-0 seo-container text-center z-0">
  <h1>{{dataPageArtas.seoTexts.h1}}</h1>
  <h2>{{dataPageArtas.seoTexts.h2}}</h2>
  <h3>{{dataPageArtas.seoTexts.h3}}</h3>
  <p>{{dataPageArtas.seoTexts.text}}</p>
</div>
<div class="bg-gray-900 mx-auto max-w-4xl  mb-[100px]"
     style="background-image: url('assets/background_logo_small.png'); background-repeat: repeat;">
    <app-about [videos]="dataPageArtas.getCardsVideos()"/>
    <app-header-form [products]="dataPageArtas.getCardsMessenges()"/>
    <app-prints [partners]="dataPageArtas.getCardsPrints()"/>
    <app-partners [partners]="dataPageArtas.getCardsPartners()"/>
    <app-advantages [partners]="dataPageArtas.getCardsAdvantages()"/>
    <app-reviews [partners]="dataPageArtas.getCardsReviews()"/>
    <!-- <app-contact-block/> -->
</div>

<app-contacts-messengers [classBg]="'bg-gray-700 '"
                         [showForm]="true" [bottom]="15" [left]="70"/>
<app-contacts-left-artas
  [classBg]="'bg-gray-800 text-yellow-400'" [showForm]="false"
/>

