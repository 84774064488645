import {Component} from '@angular/core';
import {
  NgForOf,
  NgIf
} from "@angular/common";
import {
  ActivatedRoute,
  Router,
  RouterLink
} from "@angular/router";
import {PageArtPartyServices} from "../../../../services/pages/page-art-party.services";
import {PageArtasServices} from "../../../../services/pages/page-artas.services";
import {
  basketIcon,
  favoriteIcon,
  photosIcon,
  telegramIcon
} from 'src/app/traits/icons-tools';
import { HeaderFormComponent } from '../header-form/header-form.component';
import { ContactBlockComponent } from '../contact-block/contact-block.component';
import { PartnersComponent } from '../partners/partners.component';
import { NavbarTopComponent } from 'src/app/components/pages/navbar-top/navbar-top.component';
import {ReviewsComponent} from "../reviews/reviews.component";
import {PrintsComponent} from "../prints/prints.component";
import {ContactsLeftArtasComponent} from "../../page-artas/contacts-left-artas/contacts-left-artas.component";
import {ContactsMessengersComponent} from "../../page-artas/contacts-messengers/contacts-messengers.component";
import {AdvantagesComponent} from "../advantages/advantages.component";
import {NavbarTopArtPartyComponent} from "../navbar-top-art-party/navbar-top-art-party.component";
import { AboutComponent } from '../about/about.component';

@Component({
  selector: 'app-home.component.html',
  templateUrl: './page-home.component.html',
  styleUrls:['./page-home.component.scss'],
  standalone:true,
  imports: [
    NgForOf,
    NgIf,
    RouterLink,
    HeaderFormComponent,
    ContactBlockComponent,
    PartnersComponent,
    ReviewsComponent,
    PrintsComponent,
    ContactsLeftArtasComponent,
    ContactsMessengersComponent,
    AdvantagesComponent,
    NavbarTopArtPartyComponent,
    AboutComponent
  ],
})
export class PageHomeComponent {
 public portretInks:any =this.getProductPortretInk('f4fc4lb4c') ;
 public characterInks:any =this.getProductPortretInk('w0l3k0kzl') ;
 public characterInksColor:any =this.getProductPortretInk('i2d3f6o39') ;
 public siluet:any =this.getProductPortretInk('ahkazyxck') ;
 public showOrderByCard: boolean;
  protected readonly basketIcon = basketIcon;
  protected readonly photosIcon = photosIcon;

  constructor(
    public dataPageArtas: PageArtasServices,
    public dataPageArtParty: PageArtPartyServices,
    private router: Router,
             ) {}

public getProductPortretInk(id:any){
 return  this.dataPageArtas.findCard(id);

}
  setSelect(card: any) {
    this.router.navigate(['/',card.id]);
    window.scrollTo(0, 0);

  }
  protected readonly telegramIcon = telegramIcon;
  protected readonly favoriteIcon = favoriteIcon;
}
