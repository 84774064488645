import {Component, Input} from '@angular/core';
import {CdServices} from "../../../../services/common/cd.services";
import {ServerOrderServices} from "../../../../services/servers/server-order.services";
import {BasketServices} from "../../../../services/common/basket.services";
import {getDiscount} from "../../../../traits/string-tools";
import {JsonPipe, NgClass, NgForOf, NgIf} from "@angular/common";
import {ZoomImageComponent} from "../../../../components/zoom-image/zoom-image.component";
import {zoomMinusIcon, zoomPlusIcon} from "../../../../traits/icons-tools";
import {TextBlockComponent} from "../text-block/text-block.component";
import {WrapperCardComponent} from "../wrapper-card/wrapper-card.component";

@Component({
  selector: 'app-prints',
  standalone: true,
  imports: [
    JsonPipe,
    NgForOf,
    NgIf,
    ZoomImageComponent,
    NgClass,
    TextBlockComponent,
    WrapperCardComponent
  ],
  templateUrl: './prints.component.html',
  styleUrl: './prints.component.scss'
})
export class PrintsComponent {
  @Input()  partners: any[]
  public form: any = {
    phone: '',
    comment: '',
  };
  isFormSubmitted: boolean = false;
  selectedProductIndex: number = 0;
  isFadingOut: boolean = false;

  constructor(public cd:CdServices,public serverOrder: ServerOrderServices, public basketServices: BasketServices,) {
  }

  ngOnInit(): void {
    // Инициализация может быть полезна для дополнительных данных, но здесь не требуется
  }



  handleSubmit(): void {
    this.isFormSubmitted = true;
    setTimeout(() => {
      this.isFormSubmitted = false;
    }, 3000);
    this.submit();
  }
  submit() {
    let orderCard: any = {
      url: window.location.href,
      type: 'Продукт',
      name: 'Назва послуги - '+ this.selectedProduct.name,
      desc:'Опис послуги - ' + this.selectedProduct.desc,
      srcPreview:  this.selectedProduct.srcPreview,
      cost: 'Повна вартість - '+this.selectedProduct.cost,
      discount: 'Зі знижкою - '+  this.selectedProduct.action ? this.selectedProduct.action : getDiscount(this.selectedProduct.cost)+' грн',
      action:this.cd.showActionsSales?'АКЦИЯ-'+(this.selectedProduct.action ? 'СУПЕР ЦІНА - '+this.selectedProduct.action:'Знижка 30%('+getDiscount(this.selectedProduct.cost)+' грн )'):'НЕМА (АКЦИИ)',
      comment:'Коментар та побажання - ' + this.form.comment,
      phone:this.form.phone,
    }
    this.serverOrder.sendOrder(orderCard, (responseTelegramBot: any) => {
      this.basketServices.data = {
        ...this.form, ...this.selectedProduct
      }
      this.basketServices.save()
    })
  }


  trackByProduct(index: number, item: any): number {
    return index; // Используется для улучшения производительности при рендеринге списка
  }
  get  materials() {
    return  this.selectedProduct.type.split('_').filter((word) => word.trim());
  }



  selectProduct(index: number) {
    this.isFadingOut = true; // Start fading out

    // Add a scale effect to the selected product
    const previousIndex = this.selectedProductIndex;
    this.selectedProductIndex = index;

    // Optionally, you can add a timeout to reset the scale effect
    setTimeout(() => {
      this.selectedProductIndex = previousIndex; // Reset to previous index
      this.selectedProductIndex = index; // Set to new index
      this.isFadingOut = false; // Start fading out

    }, 300); // Match this duration with the CSS transition duration
  }
  scrollLeft() {
    const thumbnails = document.getElementById('thumbnails_prints');
    if (thumbnails) {
      thumbnails.scrollBy({
        top: 0,
        left: -100, // Adjust this value to control the scroll amount
        behavior: 'smooth'
      });
    }
  }

  scrollRight() {
    const thumbnails = document.getElementById('thumbnails_prints');
    if (thumbnails) {
      thumbnails.scrollBy({
        top: 0,
        left: 100, // Adjust this value to control the scroll amount
        behavior: 'smooth'
      });
    }
  }
  get selectedProduct() {
    return this.partners[this.selectedProductIndex];
  }

  prevProduct() {
    this.isFadingOut = true; // Start fading out
    setTimeout(() => {
      if (this.selectedProductIndex > 0) {
        this.selectedProductIndex--;
      } else {
        this.selectedProductIndex = this.partners.length - 1; // Loop to the last product
      }
      this.isFadingOut = false; // End fading out
    }, 300); // Match this duration with the CSS transition duration
  }

  nextProduct() {
    this.isFadingOut = true; // Start fading out
    setTimeout(() => {
      if (this.selectedProductIndex < this.partners.length - 1) {
        this.selectedProductIndex++;
      } else {
        this.selectedProductIndex = 0; // Loop to the first product
      }
      this.isFadingOut = false; // End fading out
    }, 300); // Match this duration with the CSS transition duration
  }

  protected readonly zoomPlusIcon = zoomPlusIcon;
  protected readonly zoomMinusIcon = zoomMinusIcon;
  desc: string ='Ви можете самостійно надрукувати улюблені малюнки на різних матеріалах, таких як папір, полотно або текстиль. Поділіться своїми роботами в соціальних мережах';
}
