<app-wrapper-card>
  <app-text-block
    [name]="'Мені довіряють'"
    [text]="desc"
  />
<div class="relative">
  <!-- Left Scroll Button -->
  <button (click)="scrollLeft()" class="absolute left-0 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white rounded-full p-2 z-10">
    <i class="fas fa-chevron-left"></i> <!-- Left arrow icon -->
  </button>

  <!-- Thumbnails Container -->
  <div id="thumbnails" class="flex gap-3 overflow-x-auto p-2">
    <div *ngFor="let product of partners; let i = index; trackBy: trackByProduct"
         class="relative flex-shrink-0 w-24 h-24 cursor-pointer group transition-transform duration-300 ease-in-out"
         [class.selected-thumbnail]="i === selectedProductIndex"
         (click)="selectProduct(i)">
      <img [src]="product.srcPreview" alt="{{ product.name }}" class="w-full h-full rounded-lg shadow-lg group-hover:scale-110 group-hover:shadow-xl transition-transform duration-300 ease-in-out">
    </div>
  </div>

  <!-- Right Scroll Button -->
  <button (click)="scrollRight()" class="absolute right-0 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white rounded-full p-2 z-10">
    <i class="fas fa-chevron-right"></i> <!-- Right arrow icon -->
  </button>
</div>
</app-wrapper-card>
