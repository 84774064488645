<app-sidebar [show]="true"
(onSave)="onClose.emit(true)"
[showClose]="true"
[height]="100"
[ext]="'%'"
[zIndex]="10000"
[position]="'bottom'">


<ng-container *ngIf="card">
  <div class="z-50 -right-[0px] fixed top-[200px] bg-gray-800 bg-opacity-70 rounded-lg p-1">
    <div class="flex flex-col items-start">
     <!-- <img
        [src]="card.srcIcon"
        [alt]="dataPage.seoTexts.h1"
        class="w-24 h-24 object-cover rounded-md shadow-md opacity-90"
      >-->
      <div class="flex flex-col items-start space-y-2 p-1">
        <div *ngFor="let label of labels; let i = index" class="flex items-center">
          <span class="text-gray-400 mr-2">&#10003;</span> <!-- Checkmark glyph -->
          <div class="text-[10px] bg-opacity-90 bg-gray-800 text-gray-400 shadow-sm rounded-md px-1 py-1 transform transition duration-300 ease-out opacity-90">
            {{ label }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-view-grid-artas *ngIf="photos"
                       [cards]="photos"
                       [selectCard]="card"
  ></app-view-grid-artas>
</ng-container>
</app-sidebar>
