import {Component, Input} from '@angular/core';
import {LogoComponent} from "../../../../components/pages/logo/logo.component";
import {RouterLink} from "@angular/router";
import {NgFor, NgIf} from "@angular/common";
import {reInit} from "../../../../traits/datetime-tools";
import {TextBlockComponent} from "../text-block/text-block.component";

@Component({
  selector: 'app-about',
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    LogoComponent,
    RouterLink,
    TextBlockComponent
  ],
  templateUrl: './about.component.html',
  styleUrl: './about.component.scss'
})
export class AboutComponent {

  @Input() videos:any[];
  showVideo = true;
  showContent = true;
  currentVideoSrc:string;
  isMuted: any;

  ngOnInit(){
    this.videos = this.videos.map((item)=>{
      return {
        srcVideo: `https://server-2.artas.org.ua/Video/${item.name}.mp4`,
        srcPreview: `https://server-2.artas.org.ua/Video/${item.name}.jpg`,
        name: `${item.name}`
      }
    })
    this.currentVideoSrc = this.videos[0].srcVideo;

  }

  changeTemp(srcVideo: string) {
    const backgroundVideo: HTMLVideoElement | null = document.querySelector('#backgroundVideo');
    if (backgroundVideo) {
      backgroundVideo.pause();
      backgroundVideo.src = srcVideo;
      backgroundVideo.load();
      backgroundVideo.play();
    }
  }

  changeVideo(srcVideo: string) {
    this.showVideo = false;
    this.showContent = false;
    setTimeout(() => {
      this.currentVideoSrc = srcVideo;
      this.showVideo = true;
      this.showContent = false;
    }, 500); // Задержка для перерендеринга видео
  }

  toggleMute() {

  }
}
