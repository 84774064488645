<div class=" text-center px-6 bg-gray-800 bg-opacity-50 backdrop-blur-md rounded-xl p-8 shadow-lg">
<h2 *ngIf="name"
    class="text-center text-1xl font-extrabold text-yellow-400 mb-3">
  {{name }}
</h2>

<ng-container *ngIf="text">

  <p *ngIf="text"
     (click)="isExpanded = !isExpanded"
     class="text-center text-base text-gray-300 mb-1 leading-relaxed"
     [ngClass]="{'line-clamp-2': !isExpanded}">
    {{ text}}
  </p>

  <span [hidden]="text.length < 100"
        (click)="isExpanded = !isExpanded"
        class=" text-base text-gray-300 underline">
      {{ isExpanded ? 'Сховати' : 'Читати далі' }}
</span>
</ng-container>
</div>
