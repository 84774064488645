import {
  Component, EventEmitter, Input,
  OnInit, Output,
} from '@angular/core';
import {
  JsonPipe,
  NgClass,
  NgForOf,
  NgIf
} from "@angular/common";
import { HeaderComponent } from 'src/app/components/pages/header/header.component';
import {
  ActivatedRoute,
  Router
} from "@angular/router";
import { BoxServices } from 'src/app/services/box/box.services';
import {PageArtasServices} from "../../../../services/pages/page-artas.services";
import {reInit} from "../../../../traits/datetime-tools";
import {ViewGridArtasComponent} from "../../page-artas/view-grid/view-grid-artas.component";
import {SidebarComponent} from "../../../../components/common/sidebar/sidebar.component";
@Component({
  selector: 'app-art-party-product',
  templateUrl: './art-party-product.component.html',
  standalone:true,
  imports: [
    HeaderComponent,
    NgForOf,
    NgIf,
    ViewGridArtasComponent,
    NgClass,
    SidebarComponent,
  ],
})
export class ArtPartyProductComponent implements OnInit{
 @Input() card:any
  public photos: any[];
  public cards: any[];
  @Output() onClose = new EventEmitter<boolean>();

  constructor(public dataPage: PageArtasServices,
              public boxServices: BoxServices,
              ) {}

  ngOnInit() {
    this.initRoute();

}
  initRoute(){
      this.card
this.findInBox();
  }

  findInBox(){
    if(this.card){
      this.initBox(this.card)
      this.cards = this.dataPage.getCardsMessenges();
      this.card.gifsEnable = false;
      reInit(() => {
        this.card.gifsEnable = true
      })
      window.scrollTo(0, 0);
    }else {
      this.card = null;
      window.scrollTo(0, 0);

    }
  }
  public initBox(card){
    // boxId = boxId.replace(/\s/g, "")

    let boxId:any = this.boxFileName(card)
    this.boxServices.findCardBoxById(boxId,
      (responseBox: any): void => {
        // this.box = responseBox;
        this.photos =responseBox.photos
        console.log(responseBox,'responseBox')
      });
  }
  boxFileName(model: any) {
    let boxId = model.id + '_' + model.createAt;
    return boxId.replace(/\s/g, "");
  }


  setSelect(card: any) {
    // 'zakazat-sharj-po-foto'
    // this.card =card;
    // this.findInBox();
    window.scrollTo(0, 0);

  }

  get labels() {
  return  this.card.type.split('_').filter((word) => word.trim());
  }
}
