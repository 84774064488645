import {Component, Input} from '@angular/core';
import {LogoComponent} from "../../../../components/pages/logo/logo.component";
import {RouterLink} from "@angular/router";
import {NgClass, NgFor, NgIf} from "@angular/common";
import {reInit} from "../../../../traits/datetime-tools";

@Component({
  selector: 'app-wrapper-card',
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    NgClass,
  ],
  templateUrl: './wrapper-card.component.html',
  styleUrl: './wrapper-card.component.scss'
})
export class WrapperCardComponent {

  @Input() name:string;
  @Input() text:string;
  @Input() isExpanded:boolean;
}
