import {Injectable} from '@angular/core';
import {instagramIcon} from "../traits/icons-tools";
import {
  DomSanitizer,
  SafeResourceUrl
} from "@angular/platform-browser";

@Injectable({
  providedIn: 'root',
})


export class MyDataServices {
  public personal: any = {
    id: 1,
    name: 'Алексей',
    secondName: 'Artas',
    login: 'Alex',
    password: 'pass',
    status: true,
    token: '123',
    server: 'server.artas.org.ua',
    showPass: false,
    rem: false,
    photo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcTvEaoYEh7PiKV4BjwNBCMwbhDRSsRyHEVH7Q&usqp=CAU',
    lang: 'ru',
    city: 'kiev',
    cityCountry: 'Київ | Україна',
    flagSrc: 'https://flagcdn.com/32x24/ua.png',
    phoneOne: '066-011-80-08',
    phoneOneShort: '066...',
    phoneTwo: '098-680-93-25',
    phoneTwoShort: '098...',
    email: 'artas-alex@ya.ru',
    telegramChat: 'alex02a21b',
    instagram: 'artasalex',
    facebook: 'alex.artas.5',
    skype: 'artas-alex',
    address: 'Киев, м.Левобережная ',
    country: {
      n: 'Украина',
      text: 'По всей Украине',
      src: 'assets/img/ukr_map.png',
    },
  };
  public phones: any[] = [
    {
      label: '066',
      target: '_blank', // icon: 'fas fa-phone-volume',
      srcPreview: 'assets/icons/9073336.png',

      sw: true,
      href: 'tel:+38' + this.personal.phoneOne,

      count: null,
      command: (item: any, index: any) => {
      }
    },
    {
      label: '098',
      target: '_blank', // icon: 'fas fa-phone-volume',
      srcPreview: 'assets/icons/9073336.png',

      sw: true,
      href: 'tel:+38' + this.personal.phoneTwo,
      count: null,
      command: (item: any, index: any) => {
      }
    },
  ]
  public messengers: any[] = [
    /*{
    label: 'Написать',
    target: null,
    icon: 'fa-solid fa-basket-shopping',
    sw: true,
    // href:null,
    count: null,
    command: (item: any, index: any) => {
      this.cd.showCardOrder = true;
    }
  },*/
    {
      label: null,
      target: '_blank',
      icon: instagramIcon,
      srcPreview: 'assets/icons/1409946.png',
      sw: true,
      href: 'https://www.instagram.com/' + this.personal.instagram,
      count: null,
      command: (item: any, index: any) => {
      }
    },
    {
      label: null,
      target: '_blank',
      icon: 'fab fa-viber fa-lg icon-lg',
      srcPreview: 'assets/icons/3938039.png',

      sw: true,
      href: this.saveUrl('viber://chat?number=380660118008'),
      count: null,
      command: (item: any, index: any) => {
      }
    },
    {
      label: null,
      target: '_blank',
      icon: 'fab fa-telegram-plane fa-lg icon-lg',
      srcPreview: 'assets/icons/3488463.png',

      sw: true,
      href: 'https://telegram.me/alex02a21b',
      count: null,
      command: (item: any, index: any) => {
      }
    },
    {
      label: null,
      target: '_blank',
      icon: 'fab fa-whatsapp fa-lg icon-lg',
      srcPreview: 'assets/icons/3488379.png',
      sw: true,
      href: 'https://wa.me/380660118008',
      count: null,
      command: (item: any, index: any) => {
      }
    },

  ];
  public menuBottom: any = [
    {
      label: null,
      target: '_blank',
      icon: instagramIcon,
      srcPreview: 'assets/icons/1409946.png',
      sw: true,
      href: 'https://www.instagram.com/' + this.personal.instagram,
      count: null,
      command: (item: any, index: any) => {
      }
    },




    /*{
      label: 'Facebook',
      target: '_blank',
      icon: this.cd.theme.icon.facebook,
      sw: true,
      href: this.cd.url.facebook + this.personal.facebook,
      count: null,
      command: (item: any, index: any) => {
      }
    },*/
  ];

  constructor(public sanitizer: DomSanitizer) {
  }

  public saveUrl(url: any) {
    let urlSafe: SafeResourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    return urlSafe;
  }

}
