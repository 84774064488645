import {Component, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {LogoComponent} from "../../../../components/pages/logo/logo.component";
import {RouterLink} from "@angular/router";
import {NgClass, NgFor, NgIf} from "@angular/common";
import {reInit} from "../../../../traits/datetime-tools";

@Component({
  selector: 'app-wrapper-swiper',
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    NgClass,
  ],
  templateUrl: './wrapper-swiper.component.html',
  styleUrl: './wrapper-swiper.component.scss'
})
export class WrapperSwiperComponent {
  @Output() swipeLeft = new EventEmitter<void>();
  @Output() swipeRight = new EventEmitter<void>();

  private startX: number = 0;
  private endX: number = 0;

  constructor() {}

  @HostListener('touchstart', ['$event'])
  onTouchStart(event: TouchEvent) {
    this.startX = event.touches[0].clientX; // Get the starting touch position
  }

  @HostListener('touchmove', ['$event'])
  onTouchMove(event: TouchEvent) {
    this.endX = event.touches[0].clientX; // Get the current touch position
  }

  @HostListener('touchend', ['$event'])
  onTouchEnd() {
    const distance = this.endX - this.startX; // Calculate the distance swiped

    if (distance > 50) {
      // Swipe right
      this.swipeRight.emit();
    } else if (distance < -50) {
      // Swipe left
      this.swipeLeft.emit();
    }
  }
}
