<app-wrapper-card>
  <div class="mx-auto text-center md:max-w-xl lg:max-w-3xl">
    <app-text-block
      [name]="name"
      [text]="desc"
    />
  </div>
  <div class="relative">
    <!-- Left Scroll Button -->
    <button (click)="scrollLeft()" class="absolute left-0 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white rounded-full p-2 z-10">
      <i class="fas fa-chevron-left"></i> <!-- Left arrow icon -->
    </button>

    <!-- Testimonials Container -->
    <div #thumbnails id="thumbnails_advantages" class="flex gap-4 overflow-x-auto p-2">
      <div *ngFor="let product of partners; let i = index; trackBy: trackByProduct"
           class="relative block rounded-lg bg-gray-800 shadow-lg dark:bg-neutral-700 dark:shadow-black/30 min-w-[250px] h-[200px] overflow-hidden transition-transform duration-300 transform hover:scale-105"> <!-- Set minimum width and hide overflow -->
        <div class="absolute -top-2 -left-2   bg-yellow-400 text-white rounded-full w-10 h-10 flex items-center justify-center font-extrabold text-lg z-20">
          {{ i + 1 }} <!-- Sequential number -->
        </div>
        <div class="p-4 flex flex-col justify-between h-full"> <!-- Adjusted padding -->
          <span class="text-xs font-semibold text-gray-200 bg-gray-600 rounded-full px-2 py-1 mb-1 inline-block text-center">{{ product.name }}</span> <!-- Smaller name as label -->
          <hr class="border-gray-500 mb-1" />
          <div class="h-full overflow-y-auto flex items-center justify-center"> <!-- Enable vertical scroll for text and center it -->
            <p class="text-center text-md text-gray-300 overflow-ellipsis" style="max-height: 100px;">
              {{ product.desc }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- Right Scroll Button -->
    <button (click)="scrollRight()" class="absolute right-0 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white rounded-full p-2 z-10">
      <i class="fas fa-chevron-right"></i> <!-- Right arrow icon -->
    </button>
  </div>
</app-wrapper-card>
