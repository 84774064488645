import {Component, Input} from '@angular/core';
import {LogoComponent} from "../../../../components/pages/logo/logo.component";
import {RouterLink} from "@angular/router";
import {NgClass, NgFor, NgIf} from "@angular/common";
import {reInit} from "../../../../traits/datetime-tools";

@Component({
  selector: 'app-text-block',
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    NgClass,
  ],
  templateUrl: './text-block.component.html',
  styleUrl: './text-block.component.scss'
})
export class TextBlockComponent {

  @Input() name:string;
  @Input() text:string;
  @Input() isExpanded:boolean;
}
