<section class="relative h-screen w-full overflow-hidden">
  <!-- Background Video -->
  <video *ngIf="showVideo"
         #backgroundVideo
         class="absolute inset-0 h-full w-full object-cover z-0"
         autoplay muted loop playsinline>
    <source [src]="currentVideoSrc" type="video/mp4">
    Your browser does not support the video tag.
  </video>
  <div
    class="absolute w-[200px] z-20 top-4 left-4 bg-gray-800 bg-opacity-80 text-yellow-400 text-xl p-4 rounded-full shadow-lg focus:outline-none transition-transform transform hover:scale-105">
    <div class="flex items-center">
      <img src="assets/logo.jpg" alt="Logo" class="rounded-md h-8 w-8 mr-2 shadow">
      <span class="text-white text-md font-bold uppercase"
            style="font-family: Roboto,serif;"
      >art-party</span>
    </div>
    <div class="z-20 absolute font-bold  bottom-[5px] left-[50px] text-amber-200 text-[10px]">
      15 років малюю для Вас!
    </div>
  </div>
  <div
          class="absolute z-20 top-4 right-4 bg-gray-800 bg-opacity-80 text-yellow-400 text-xl p-4 rounded-full shadow-lg focus:outline-none transition-transform transform hover:scale-105">
    <div class="flex items-center">
      <img [src]="'assets/flagUa.png'"
           class=" animate-bounce animate-infinite animate-duration-[2000ms] w-[15px] h-[10px ] mr-3" alt="">
      <span
        class="   text-pink-100 self-center text-[12px] font-semibold whitespace-nowrap">
        {{ 'Київ | Україна' }}
      </span>
    </div>
  </div>


  <!-- Overlay Content -->
  <div *ngIf="showContent"
       id="content"
       class="absolute inset-0 bg-gray-900 bg-opacity-50 flex flex-col items-center justify-center transition-all duration-500">
      <app-text-block
        name="Про мене"
        text="Я художник із досвідом понад 15 років у створенні художніх робіт по фотографіях та на заходах. Кожна моя робота — це історія, наповнена душею, теплом та унікальністю."
      />
  </div>

  <div class="absolute bottom-8 left-0 w-full flex overflow-x-auto no-scrollbar space-x-4 px-6">
    <div id="thumbnails_products" class="flex gap-3 overflow-x-auto p-2">
      <div *ngFor="let video of videos; let i = index;"
           class="relative filter-item flex-shrink-0 w-24 h-24 cursor-pointer group transition-transform duration-300 ease-in-out"
           (click)="changeVideo(video.srcVideo)">
        <div class="absolute top-2 left-2 bg-black bg-opacity-70 text-yellow-400 px-2 py-1 rounded text-xs">
          {{ video.name }}
        </div>
        <img [src]="video.srcPreview" alt="" class="w-full h-full rounded-lg shadow-lg group-hover:scale-110 group-hover:shadow-xl transition-transform duration-300 ease-in-out">
      </div>
    </div>
  </div>
</section>
