import {
  Component,
  OnInit,
  Input,
} from '@angular/core';
import {
  NgClass,
  NgForOf,
  NgIf
} from "@angular/common";
import { FormsModule, NgForm } from '@angular/forms';
import { CdServices } from 'src/app/services/common/cd.services';
import { ServerOrderServices } from 'src/app/services/servers/server-order.services';
import { BasketServices } from 'src/app/services/common/basket.services';
import {extractCost, getDiscount} from 'src/app/traits/string-tools';
import {ZoomImageComponent} from "../../../../components/zoom-image/zoom-image.component";
import {closeIcon, filterIcon, zoomMinusIcon, zoomPlusIcon} from "../../../../traits/icons-tools";
import {ArtPartyProductComponent} from "../art-party-product/art-party-product.component";
import {OverlayPanelModule} from "primeng/overlaypanel";
import {TextBlockComponent} from "../text-block/text-block.component";
import {WrapperCardComponent} from "../wrapper-card/wrapper-card.component";
import {WrapperSwiperComponent} from "../wrapper-swiper/wrapper-swiper.component";

@Component({
  selector: 'app-header-form',
  templateUrl: './header-form.component.html',
  standalone: true,
  imports: [
    NgForOf,
    NgIf,
    FormsModule,
    NgClass,
    ZoomImageComponent,
    ArtPartyProductComponent,
    OverlayPanelModule,
    TextBlockComponent,
    WrapperCardComponent,
    WrapperSwiperComponent
  ],

})
export class HeaderFormComponent implements OnInit {
@Input()  products: any[]
public form: any = {
  phone: '',
  comment: '',
};
  isFormSubmitted: boolean = false;
  selectedProductIndex: number = 0;
  isFadingOut: boolean = false;
  filtersApplied = false;
  currentFilterType: string | null = null;

  protected readonly zoomMinusIcon = zoomMinusIcon;
  protected readonly zoomPlusIcon = zoomPlusIcon;
  constructor(public cd:CdServices,public serverOrder: ServerOrderServices, public basketServices: BasketServices,) {
  }

  ngOnInit(): void {
    this.filteredProducts = [...this.products.sort((a, b) => extractCost(a.cost) - extractCost(b.cost))];
    this.selectedProduct  = this.filteredProducts[0];
  }

  onSwipeLeft() {
    console.log('Swiped left!');
    // Handle left swipe
  }

  onSwipeRight() {
    console.log('Swiped right!');
    // Handle right swipe
  }

  submitted = false;
  handleSubmit(): void {
    this.submitted = true;
    this.isFormSubmitted = true;
    setTimeout(() => {
      this.isFormSubmitted = false;
    }, 3000);
    this.submit();
  }
  submit() {
    let orderCard: any = {
      url: window.location.href,
      type: 'Продукт',
      name: 'Назва послуги - '+ this.selectedProduct.name,
      desc:'Опис послуги - ' + this.selectedProduct.desc,
      srcPreview:  this.selectedProduct.srcPreview,
      cost: 'Повна вартість - '+this.selectedProduct.cost,
      discount: 'Зі знижкою - '+  this.selectedProduct.action ? this.selectedProduct.action : getDiscount(this.selectedProduct.cost)+' грн',
      action:this.cd.showActionsSales?'АКЦИЯ-'+(this.selectedProduct.action ? 'СУПЕР ЦІНА - '+this.selectedProduct.action:'Знижка 30%('+getDiscount(this.selectedProduct.cost)+' грн )'):'НЕМА (АКЦИИ)',
      comment:'Коментар та побажання - ' + this.form.comment,
      phone:this.form.phone,
    }
    this.serverOrder.sendOrder(orderCard, (responseTelegramBot: any) => {
      this.basketServices.data = {
        ...this.form, ...this.selectedProduct
      }
      this.basketServices.save()
    })
  }


  trackByProduct(index: number, item: any): number {
    return index; // Используется для улучшения производительности при рендеринге списка
  }
get  materials() {
    return  this.selectedProduct.type.split('_').filter((word) => word.trim());
    }



  selectProduct(productItem:object,index: number) {
    this.isFadingOut = true; // Start fading out
    this.selectedProduct = productItem;
    // Add a scale effect to the selected product
    const previousIndex = this.selectedProductIndex;
    this.selectedProductIndex = index;

    // Optionally, you can add a timeout to reset the scale effect
    setTimeout(() => {
      this.selectedProductIndex = previousIndex; // Reset to previous index
      this.selectedProductIndex = index; // Set to new index
      this.isFadingOut = false; // Start fading out

    }, 300); // Match this duration with the CSS transition duration
  }
  scrollLeft() {
    const thumbnails = document.getElementById('thumbnails');
    if (thumbnails) {
      thumbnails.scrollBy({
        top: 0,
        left: -100, // Adjust this value to control the scroll amount
        behavior: 'smooth'
      });
    }
  }
  ngAfterViewInit(){
  }
  scrollRight() {
    const thumbnails = document.getElementById('thumbnails_products');
    if (thumbnails) {
      thumbnails.scrollBy({
        top: 0,
        left: 100, // Adjust this value to control the scroll amount
        behavior: 'smooth'
      });
    }
  }
  // get selectedProduct() {
  //   return this.products[this.selectedProductIndex];
  // }
  selectedProduct:any
  prevProduct() {
    this.isFadingOut = true; // Start fading out
    setTimeout(() => {
      const currentIndex = this.filteredProducts.indexOf(this.selectedProduct); // Get the current product index

      if (currentIndex > 0) {
        this.selectedProduct = this.filteredProducts[currentIndex - 1]; // Assign the previous product
      } else {
        this.selectedProduct = this.filteredProducts[this.filteredProducts.length - 1]; // Loop to the last product
      }

      this.isFadingOut = false; // End fading out
    }, 300); // Match this duration with the CSS transition duration
  }

  nextProduct() {
    this.isFadingOut = true; // Start fading out
    setTimeout(() => {
      const currentIndex = this.filteredProducts.indexOf(this.selectedProduct); // Get the current product index

      if (currentIndex < this.filteredProducts.length - 1) {
        this.selectedProduct = this.filteredProducts[currentIndex + 1]; // Assign the next product
      } else {
        this.selectedProduct = this.filteredProducts[0]; // Loop to the first product
      }

      this.isFadingOut = false; // End fading out
    }, 300); // Match this duration with the CSS transition duration
  }



  filteredProducts :any[]
  showProduct: any =false;
  showFilters: boolean;
  showForm: boolean;
  filterProductsByCost(category: string) {
    if (category === 'Бюджетні') {
      this.filteredProducts = this.products.filter(product => extractCost(product.cost) < 500);
    } else if (category === 'Середні') {
      this.filteredProducts = this.products.filter(product =>  extractCost(product.cost) >= 500 &&  extractCost(product.cost) < 1000);
    } else if (category === 'Преміум') {
      this.filteredProducts = this.products.filter(product => extractCost(product.cost) >= 1000);
    }
    this.filtersApplied = true;
    this.currentFilterType = category;


  }
  filterAdditionally(category: string) {
    this.filteredProducts = this.products.filter(product => product.additionally === category);
    this.filtersApplied = true;
    this.currentFilterType = category;

  }
  resetFilters() {
    this.filteredProducts = [...this.products];
    this.filtersApplied = false;
    this.currentFilterType = null;
  }

  protected readonly filterIcon = filterIcon;
  protected readonly closeIcon = closeIcon;
}
