<app-wrapper-card>
  <div class="mx-auto text-center md:max-w-xl lg:max-w-3xl">
    <app-text-block
      [name]="'Відгуки'"
    />
  </div>
  <div class="relative">
    <!-- Left Scroll Button -->
    <button (click)="scrollLeft()" class="absolute left-0 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white rounded-full p-2 z-10">
      <i class="fas fa-chevron-left"></i> <!-- Left arrow icon -->
    </button>

    <!-- Testimonials Container -->
    <div #thumbnails id="thumbnails_2" class="flex gap-4 overflow-x-auto p-2">
      <div *ngFor="let product of partners; let i = index; trackBy: trackByProduct"
           class="block rounded-lg bg-gray-800 shadow-lg dark:bg-neutral-700 dark:shadow-black/30 min-w-[250px] h-[200px] overflow-hidden transition-transform duration-300 transform hover:scale-105"> <!-- Set minimum width and hide overflow -->
        <div class="p-4 flex flex-col justify-between h-full"> <!-- Adjusted padding -->
<!--          <span class="text-xs w-[100px] font-semibold text-gray-200 bg-gray-600 rounded-full px-2 py-1 mb-1 inline-block">{{ product.name }}</span> &lt;!&ndash; Smaller name as label &ndash;&gt;-->
          <hr class="border-gray-500 mb-1" />

          <!-- Conditional rendering for image or text -->
          <ng-container *ngIf="product.srcPreview; else textContent">
            <div class="h-full overflow-y-auto"> <!-- Enable vertical scroll for images -->
              <div
                class=" rounded-lg shadow-lg  border border-zinc-700 text-center aspect-square relative  inline-block transition-transform transform hover:scale-105">
                <img *ngIf="!product.showZoom"
                     [src]="product.srcPreview" alt="upload_image"

                >
                <app-zoom-image *ngIf="product.showZoom"
                                [srcImage]="product.srcPreview">
                </app-zoom-image>

                <button (click)="product.showZoom = !product.showZoom;"
                        class=" z-20 text-sm absolute w-[50px] h-[50px] top-0 right-2 bg-zinc-900 text-white p-1 rounded-full transition-transform transform hover:scale-125"
                >
                  <i *ngIf="!product.showZoom" [class]="zoomPlusIcon"></i>
                  <i *ngIf="product.showZoom" [class]="zoomMinusIcon"></i>

                </button>


              </div>

<!--              <img [src]="product.srcPreview" alt="{{ product.name }}" class="w-full h-full object-cover rounded-lg" />-->
            </div>
          </ng-container>
          <ng-template #textContent>
              <div class="h-full overflow-y-auto flex items-center justify-center"> <!-- Enable vertical scroll for text and center it -->
                <p class="text-center text-md text-gray-300  overflow-ellipsis" style="max-height: 100px;">
                  {{ product.name }}
                </p>
              </div>
          </ng-template>
        </div>
      </div>
    </div>
    <!-- Right Scroll Button -->
    <button (click)="scrollRight()" class="absolute right-0 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white rounded-full p-2 z-10">
      <i class="fas fa-chevron-right"></i> <!-- Right arrow icon -->
    </button>
  </div>
</app-wrapper-card>
