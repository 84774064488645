<div id="list-feed"
     [class]="bgColor"
     [style.background-image]="'url(' + bgImage + ')'"
class="bg-[length:50px_50px] bg-opacity-10  bg-repeat min-h-screen flex justify-center items-center">
  <div class="mt-8 md:px-1 md:grid md:grid-cols-2 lg:grid-cols-3 gap-2 space-y-4 md:space-y-0">
    <ng-container
      *ngFor="let  card  of  cards;">
      <ng-container >
          <app-card-artas
            [card]="card"
            (onSelect)="onSelect.emit($event)"
            (onSelectCourse)="onSelectCourse.emit($event)"
            (onShowPhotos)="onShowPhotos.emit($event)"
            (onClickRoute)="onClickRoute.emit($event)"
            (onFrameShow)="onFrameShow.emit($event)"
          ></app-card-artas>
      </ng-container>
    </ng-container>
  </div>
</div>
